<template>
  <ul class="d-flex flex-wrap align-items-center">
    <li
      v-for="(item, index) in filteredMenuItems"
      :key="index"
      class="px-6"
    >
      <b-link
        :class="`px-12 py-4${
          path.split('/')[1] == item.header.toLowerCase() ? ' active' : ''
        }${
          (path.split('/')[1] == 'components' && item.header.toLowerCase() == 'user interface') ? ' active' : ''
        }`"
      >
        <span>{{ item.header }}</span>
        <i class="ri-arrow-down-s-line" />
      </b-link>

      <ul class="dropdown-menu">
        <li
          v-for="(item, index) in item.children"
          :key="index"
        >
          <b-link
            v-if="item.children"
            :class="`dropdown-item${
              path.split('/')[2] == item.id ? ' active' : ''
            }`"
          >
            <span>
              <i
                v-if="item.icon"
                :class="`iconly-${item.icon}`"
              />
              <div
                v-if="item.iconSvg"
                class="submenu-item-icon"
                v-html="item.iconSvg"
              />

              <span>{{ item.title }}</span>
            </span>

            <i class="dropdown-menu-arrow" />
          </b-link>

          <b-link
            v-else
            :class="`${path == item.navLink ? 'active' : ''}`"
            :to="item.navLink"
            :style="item.tag ? 'pointer-events: none;' : ''"
          >
            <span
              v-if="item.tag"
              class="justify-content-between w-100"
            >
              <div class="d-flex align-items-center">
                <i
                  v-if="item.icon"
                  :class="`iconly-${item.icon}`"
                />
                <div
                  v-if="item.iconSvg"
                  class="submenu-item-icon"
                  v-html="item.iconSvg"
                />

                <span style="white-space: nowrap">{{ item.title }}</span>
              </div>

              <b-badge
                v-if="item.tag"
                variant="none"
                class="hp-text-color-black-100 hp-bg-success-3 rounded-pill px-8 border-0 ml-32"
              >
                {{ item.tag }}
              </b-badge>
            </span>

            <span v-else>
              <i
                v-if="item.icon"
                :class="`iconly-${item.icon}`"
              />
              <div
                v-if="item.iconSvg"
                class="submenu-item-icon"
                v-html="item.iconSvg"
              />

              <span>{{ item.title }}</span>
            </span>
          </b-link>

          <ul
            v-if="item.children"
            class="dropdown-menu"
          >
            <li
              v-for="(item, index) in item.children"
              :key="index"
              class="dropend"
            >
              <b-link
                v-if="item.children"
                :class="`dropdown-item${
                  path.split('/')[3] == item.id ? ' active' : ''
                }`"
              >
                <span>{{ item.title }}</span>

                <i class="dropdown-menu-arrow" />
              </b-link>

              <b-link
                v-else-if="item.target"
                :href="item.navLink"
                :class="`${path == item.navLink ? ' active' : ''}`"
                :target="item.target"
              >
                <span>{{ item.title }}</span>
              </b-link>

              <b-link
                v-else
                :to="item.navLink"
                :class="`${path == item.navLink ? ' active' : ''}`"
              >
                <span>{{ item.title }}</span>
              </b-link>

              <ul
                v-if="item.children"
                class="dropdown-menu"
              >
                <li
                  v-for="(item, index) in item.children"
                  :key="index"
                >
                  <b-link
                    :to="item.navLink"
                    :class="`${path == item.navLink ? ' active' : ''}`"
                  >
                    <span>{{ item.title }}</span>
                  </b-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </li>

    <li class="px-6">
      <a
        href="#"
        target="_self"
        class="px-12 py-4"
        @click="redirect"
      ><span>Mi Edificio</span></a>
    </li>
  </ul>
</template>

<script>
import {
  BLink, BBadge,
} from 'bootstrap-vue'

import navigation from '../../../../navigation'

export default {
  components: {
    BLink,
    BBadge,
  },
  data() {
    return {
      path: '',
      actualBuilding: '',
      menuItems: navigation,
    }
  },
  computed: {
    filteredMenuItems() {
      const userStr = localStorage.getItem('home_app_user')
      const user = userStr ? JSON.parse(userStr) : null
      if (user && user.user.is_super_admin) {
        return this.menuItems
      }
      return this.menuItems.filter(item => item.header !== 'SuperAdmin')
    },
  },
  watch: {
    $route() {
      this.getRoute()
    },
  },
  created() {
    this.getRoute()
  },
  beforeMount() {
  },
  methods: {
    getRoute() {
      this.path = this.$route.path
    },
    redirect(e) {
      e.preventDefault()
      const selected = localStorage.getItem('home_app_selectBuilding') ?? ''
      this.actualBuilding = `/admin/building/${selected}`
      this.$router.push(this.actualBuilding)
    },
  },
}
</script>
